<template>
    <div style="border: 1px solid var(--global-muted-color);" class="">

        <div class="media-manager">
            <div style="border: 1px solid var(--global-muted-color);" class="menu uk-flex uk-flex-gap-xs uk-flex-wrap uk-margin-small-bottom">
                <div class="menu-item uk-flex uk-flex-start-uk-flex-center uk-flex-middle uk-flex-gap-s uk-padding-small uk-padding-remove-vertical uk-background-muted">
                    <span class="uk-flex uk-flex-center uk-flex-middle" uk-icon="check"></span>
                    <span class="">Action</span>
                </div>

                <div class="menu-item uk-flex uk-flex-start-uk-flex-center uk-flex-middle uk-flex-gap-s uk-padding-small uk-padding-remove-vertical uk-background-muted">
                    <span class="uk-flex uk-flex-center uk-flex-middle" uk-icon="check"></span>
                    <span class="">Action</span>
                </div>

                <div class="menu-item uk-flex uk-flex-start-uk-flex-center uk-flex-middle uk-flex-gap-s uk-padding-small uk-padding-remove-vertical uk-background-muted">
                    <span class="uk-flex uk-flex-center uk-flex-middle" uk-icon="check"></span>
                    <span class="">Action</span>
                </div>

                <div class="menu-item uk-flex uk-flex-start-uk-flex-center uk-flex-middle uk-flex-gap-s uk-padding-small uk-padding-remove-vertical uk-background-muted">
                    <span class="uk-flex uk-flex-center uk-flex-middle" uk-icon="check"></span>
                    <span class="">Action</span>
                </div>
            </div>

            <div class="media-manager-grid">
                <div class="side-nav" style="border: 1px solid var(--global-muted-color)">folder<br>tree</div>
                <div class="header">
                    <!-- <div class="menu">menu</div> -->
                    <!--
                    <div class="menu uk-flex uk-flex-start uk-flex-gap-small">
                        <div class="action uk-padding-small" style="border: 1px solid red; width: 50px" v-for="i in 12">
                            X
                        </div>
                    </div>-->

                    <div class="path uk-flex uk-flex-gap-s">
                        <form-button icon="arrow-left"
                                     type="muted"
                                     @click=" ! isLoading && canGoBack && goBack()"
                                     :loading="isLoading"
                                     :disabled=" ! canGoBack"
                                     :showSlot="false"></form-button>
                        <form-input type="text"
                                    :container-margin="false"
                                    v-model="currentPath"
                                    :showLabel="false"
                                    class=" uk-width-expand"
                                    label="core.mediaManager.pathLabel"></form-input>
                        <form-button icon="refresh"
                                     type="muted"
                                     @click=" ! isLoading && refreshCurrentPath()"
                                     :loading="isLoading"
                                     :showSlot="false"></form-button>
                    </div>
                </div>
                <div class="main"  style="border: 1px solid var(--global-muted-color);">
                    <div class="items uk-padding-small uk-flex uk-flex-wrap uk-flex-gap-m"
                         v-if="pathHasFiles"
                         v-show=" ! isLoading"
                    >
                        <div class="item  uk-flex uk-flex-column uk-flex-center uk-flex-middle uk-background-muted"
                             style="width: 120px; height: 120px;"
                             v-for="item in displayItems"
                             :item="item"
                             :key="item.name">

                            <div class="item__inner uk-flex uk-flex-column uk-flex-center uk-flex-middle uk-padding-small"
                                 v-if=" item.isFolder"
                                 :uk-tooltip="'title: '+item.name+'; pos: bottom'"
                                 @click="goToRelativePath(item.name)"
                            >
                                <div class="item-top">
                                    <span uk-icon="icon:folder;ratio:2.5"></span>
                                </div>
                                <div class="item-bottom">
                                    {{ truncateName(item.name) }}
                                </div>
                            </div>

                            <div class="item__inner uk-flex uk-flex-column uk-flex-center uk-flex-middle uk-padding-small"
                                v-if=" ! item.isFolder && isImage(item)"
                                 @contextmenu="showContextMenu(item, $event)"
                                 :uk-tooltip="'title: '+item.name+'; pos: bottom'">
                                <div class="item-top">
                                    <img :src="getFileWebPath(item)" class="item-image">
                                    <!--  <img :src="'data:image/png;base64,'+item.raw" class="item-image"> -->
                                </div>
                                <div class="item-bottom">
                                    {{ truncateName(item.name) }}
                                </div>
                            </div>

                            <div class="item__inner uk-flex uk-flex-column uk-flex-center uk-flex-middle uk-padding-small"
                                 :uk-tooltip="'title: '+item.name+'; pos: bottom'"
                                 v-if=" ! item.isFolder && ! isImage(item) ">
                                <div class="item-top">
                                    <span uk-icon="icon:file-text;ratio:2.5"></span>
                                </div>
                                <div class="item-bottom">
                                    {{ truncateName(item.name) }}
                                </div>
                            </div>

                            <div uk-dropdown="mode: click" :ref="getContextMenuRefNameByItem(item)">
                                <ul class="uk-nav uk-nav-default">
                                    <li>
                                        <a href="#" @click.prevent="doRenameItemFlow(item)">
                                            <span class="uk-margin-small-right uk-text-secondary" uk-icon="icon: pencil"></span ><span class="uk-text-secondary">{{ translateUcFirst('core.mediaManager.rename') }}</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#"  @click.prevent="doDeleteItemFlow(item)">
                                            <span class="uk-margin-small-right uk-text-secondary" uk-icon="icon: close"></span> <span class="uk-text-secondary">{{ translateUcFirst('core.mediaManager.delete') }}</span>
                                        </a>
                                    </li>

                                </ul>
                            </div>

                        </div>
                    </div>
                    <div class="no-items uk-padding-small uk-flex uk-flex-wrap uk-flex-gap-m" v-else>
                        <h4>{{ translate('core.mediaManager.emptyDirectory') }}</h4>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import {computed, ref} from 'vue';
import asyncOperations from "@/client/extensions/composition/asyncOperations";
import {useStore} from "vuex";
export default {
    props: {
        webPath : {
            type: String,
            default: 'http://localhost:3001/api/files/uploads'
        }
    },
    setup (props, setupContext ) {
        let {asyncOps, asyncOpsReady, asyncStatus} = asyncOperations(props);

        return {asyncOps, asyncOpsReady, asyncStatus,}
    },
    data: function () {
        return {
            previousPath : '/',
            currentPath : '/',
            items : [],
            asyncData: {
                items: {
                    target: 'files/list',
                    data: computed( () => {
                        return {
                            path: this.currentPath
                        }
                    })
                }
            }
        };
    },
    computed : {
        isLoading () {
            return this.asyncStatus.asyncDataLoading || ! this.asyncStatus.asyncDataReady
        },
        canGoBack () {
            return this.oldPath != this.currentPath
        },
        pathHasFiles () {
           return this.displayItems.length > 0;
        },

        displayItems() {
            if (Array.isArray(this.items) && this.items.length > 0) {
                let displayItems = [...this.items];
                displayItems.sort((a,b) => {
                    if ( a.isFolder && ! b.isFolder) {
                        return 0;
                    }

                    if ( ! a.isFolder && b.isFolder) {
                        return 1;
                    }

                    return a.name > b.name;
                });
                return displayItems;
            }

            return [];
        }
    },
    methods: {
        getContextMenuRefNameByItem(item) {
            return 'context_menu_'+item.name;
        },
        showContextMenu (item, event) {
            event.preventDefault();
            event.target.click();// trigger drop down. doing so with the UIkit API causes some UIkit issue
        },
        async refreshCurrentPath() {
            await this.asyncOps.fetchAsyncData(null, this);
        },
        goBack() {
            if ( this.currentPath == '' || this.currentPath == '/'){
                return;
            }

            let newPath = this.currentPath.split('/');
            newPath.pop();

            newPath = newPath.join('/');
            if (newPath == '') {
                newPath = '/';
            }
            this.currentPath = newPath;

        },
        isImage(input) {
            let imageExtensions = ['jpg','jpeg','png','gif','webp'];
            let extension;

            if (typeof input === 'string') {
                extension = input.extension
            } else {
                extension = input.extension ?? '';
            }
            return imageExtensions.includes(extension);
        },
        getFileRaw(arg) {
            let result = ref('');
            console.log('call', arg);

            setTimeout( () => {
                console.log('timeout');

            }, 5);
            /*

            */
            return result;



        },
        getFileWebPath(arg) {
            let raw = this.currentPath + '/' + arg.name;
            let path = this.webPath;

            if (this.webPath.endsWith('/')) {
                path = path.substr(0, path.length - 1)
            }

            path =  path + raw.replace(/\/+/g, '/');
            path = path + this.getAuthString();
            return path;
        },
        goToRelativePath(arg) {
            this.currentPath = this.currentPath + '/' + arg;
        },
        truncateName(name) {
            let length = 17;
            let final = name.substring(0, length);

            if (final.length != name.length) {
                final = final+'...'
            }

            return final;
        },
        getAuthString() {
            let token = this.$store.getters['user/token'];
            return '?authorization='+token;
        },
        doRenameItemFlow  (item) {
            let filename = item.name.split('.');
            let extension = filename.pop();
            filename = filename.join('.');
            this.$saffron.ui.modal.prompt(
                this.translate('core.mediaManager.renameFieldTitle', {filename: filename}),
                this.translate('core.mediaManager.renameModalTitle'),
            ).then (async (result) => {
                // this means user rejected the modal
                if (result === null) {
                    return;
                }

                // bad renaming
                if (typeof result !== 'string') {
                    this.$saffron.ui.notification(this.translate('core.mediaManager.renameInputError'));
                    return;
                }

                // check if file exists by this name
                let checkPasses = true;
                let newNameWithExtension = result + '.' + extension;

                this.items.forEach((item) => {
                    if (item.name === newNameWithExtension) {
                        checkPasses = false;
                        return false;
                    }
                });

                if ( ! checkPasses) {
                    this.$saffron.ui.notification(this.translate('core.mediaManager.renameErrorNameTaken'));
                    return;
                }
                // all clear - send the reuqest now
                let path = (this.currentPath + '/' + item.name).replace('//','/');
                let callResult = await this.asyncOps.asyncCall('files/uploads', {path, newName: newNameWithExtension}, {method: 'patch'})
               if (callResult.isError) {
                   this.$saffron.ui.notification(this.translate('core.mediaManager.renameErrorGeneric'));
                   return;
               } else {
                   this.$saffron.ui.notification(this.translate('core.mediaManager.renameSuccess', 'success'));
                   this.refreshCurrentPath();
               }

            }, (result) => {});

        },
        doDeleteItemFlow (item) {
            console.log('todo: doDeleteItemFlow');

            this.$saffron.ui.modal.confirm(
                this.translate('core.mediaManager.deleteModalContent'),
                this.translate('core.mediaManager.deleteModalTitle'),
            ).then (async (result) => {
                // all clear - send the reuqest now
                let path = (this.currentPath + '/' + item.name).replace('//','/');
                let callResult = await this.asyncOps.asyncCall('files/uploads', {path}, {method: 'delete'})
                if (callResult.isError) {
                    this.$saffron.ui.notification(this.translate('core.mediaManager.deleteErrorGeneric'));
                    return;
                } else {
                    this.$saffron.ui.notification(this.translate('core.mediaManager.deleteSuccess', 'success'));
                    this.refreshCurrentPath();
                }

            }, (result) => {
              //  console.log('reject')
            });
        }

    },
    watch: {
        items (newVal) {

        },
        currentPath (newVal, oldVal) {
            let clean =  newVal.replace(/\/+/g, '/');

            if (newVal === '') {
                this.currentPath = '/';
                return '/';
            }
            if (newVal !== clean) {
                this.currentPath = clean;
                return clean;
            }
            this.oldPath = oldVal;
        }
    },
    created () {

    }

}
</script>

<style scoped lang="scss">
    .media-manager {
        width: 100%;
    }
    .menu {
        padding: 5px ;
    }

    .path {
        padding-inline-end: calc(var(--global-margin) * 0.75);

        .uk-button {
            padding: 0 calc(var(--global-margin) * 0.75);
        }
    }
    .media-manager-grid {
        display: grid;
        grid-template-rows: 40px  auto;
        grid-template-columns: 200px  auto;
        grid-gap: 20px;
    }

    .side-nav {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 3;

    }

    .header {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 2;

    }

    .main {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 3;
        min-height: 400px;
    }

    .item {
        cursor: pointer;
        border: 1px solid var(--global-muted-color);


        &:hover {
            border: 1px solid var(--global-primary-background);
            box-shadow: 0 0 5px 0px rgba(0,0,0,0.2);
        }
    }

    .item__inner {
        height: 100%;
        width: 100%;
        flex: 100%;
    }
    .item-bottom {
        word-break: break-all;
        max-height: 45px;
        line-height: 1.1;
        margin-top: calc(var(--global-margin) * 0.5);
      //  overflow: hidden;
    }
    .item-image {
        max-width: 100%;
        max-height: 50px;
    }
</style>
